import { Auth } from '@aws-amplify/auth';
import { Store } from '@website/Store';
import { useEventFan } from 'eventfan';
import { Dispatch, useContext, useEffect, useRef, useState } from 'react';
import { Button, Modal, Tab, Tabs } from 'react-bootstrap';
import EmailVerificationForm from './utils/EmailVerificationForm';
import LoginForm from './utils/LoginForm';
import NewPasswordRequiredForm from './utils/NewPasswordRequiredForm';
import SignUpForm from './utils/SignUpForm';
import SocialLoginButtons from './utils/SocialLoginButtons';

/**
 * Login Container
 *
 * Shows the login form by default, and then the new password required form or the email verification form if these
 * steps are required.
 */
export function LoginContainer({
  onSuccess,
  onCancel = () => {},
}: LoginContainerProps) {
  const [newPasswordRequiredDetails, setNewPasswordRequiredDetails] = useState<{
    email: string;
    currentPassword: string;
  }>();
  const [userToBeVerified, setUserToBeVerified] = useState<{
    email: string;
    password: string;
  }>();

  // If a new password is required, show that form
  if (newPasswordRequiredDetails) {
    return (
      <NewPasswordRequiredForm
        {...newPasswordRequiredDetails}
        onSuccess={onSuccess}
      />
    );
  }

  // If the email needs verifying, show that form
  if (userToBeVerified) {
    return (
      <EmailVerificationForm {...userToBeVerified} onSuccess={onSuccess} />
    );
  }

  // Show the Login Form by default
  return (
    <>
      <LoginForm
        onLoginSuccess={onSuccess}
        onNewPasswordRequired={(email, currentPassword) =>
          setNewPasswordRequiredDetails({ email, currentPassword })
        }
        onNotVerifiedError={setUserToBeVerified}
        onCancel={onCancel}
      />
    </>
  );
}

/**
 * Sign up container
 *
 * Shows the sign up form by default, and then the verification code form when required.
 */
export function SignUpContainer({ onSuccess }: SignUpContainerProps) {
  const [userToBeVerified, setUserToBeVerified] = useState<{
    email: string;
    password: string;
  }>();

  if (userToBeVerified) {
    return (
      <EmailVerificationForm {...userToBeVerified} onSuccess={onSuccess} />
    );
  }

  return (
    <>
      <SignUpForm setUserToBeVerified={setUserToBeVerified} />
    </>
  );
}

/**
 * Set the user to the global Store
 *
 * Note this calls `Auth.currentAuthenticatedUser` which is async, but in practice it should be very fast as it just
 * gets the user details from memory, so we don't need a loading state whilst this is happening.
 */
export async function setUserToGlobalStore(dispatch: Dispatch<any>) {
  const user = await Auth.currentAuthenticatedUser();
  const payload = user.signInUserSession.idToken.payload as CognitoTokenPayload;

  // If a user has no host roles, the token returns an empty string. We want to
  // replace this (which gives a host roles array as `[""]`) with a completely
  // empty array (`[]`).
  const hostRoles =
    payload?.hostRoles?.split(', ').filter((i) => i !== '') || [];

  // Set the user details to global state
  const storeUser = {
    first_name: payload.given_name,
    last_name: payload.family_name,
    email: payload.email,
    id: payload.userID,
    roles: payload?.['cognito:groups'] || [],
    host_roles: hostRoles,
  };

  dispatch({ type: 'SET_USER', payload: storeUser });
}

/**
 * Authentication Modal View
 */
export enum AuthenticationModalView {
  LOGIN,
  SIGN_UP,
  // show button instead of form
  HIDE_FORM,
}

export function AuthenticateForm({
  onSuccess,
  onCancel,
}: {
  onSuccess?: (action: 'login' | 'signup') => void;
  onCancel?: () => void;
}) {
  const { dispatch } = useContext(Store);
  const [showEmailSignIn, setShowEmailSignin] = useState<boolean>(false);

  const emailSignInRef = useRef<HTMLDivElement>();

  async function successOrchestrator(action: 'login' | 'signup') {
    await setUserToGlobalStore(dispatch);
    if (onSuccess) onSuccess(action);
  }

  return (
    <div>
      <SocialLoginButtons
        onSuccessCallback={() => successOrchestrator('login')}
      />

      <div className="d-grid gap-2 mt-2">
        <Button
          variant="outline-primary"
          onClick={() => {
            setShowEmailSignin(!showEmailSignIn);

            setTimeout(
              () =>
                emailSignInRef?.current?.scrollIntoView({
                  behavior: 'smooth',
                  block: 'nearest',
                }),
              20,
            );
          }}
        >
          Login/Sign up with email
        </Button>
      </div>
      <style jsx global>{`
        .nav-item .nav-link {
          padding: 0.5rem 1rem;
        }
      `}</style>
      <div ref={emailSignInRef}>
        {showEmailSignIn && (
          <div className="mt-3">
            <Tabs defaultActiveKey="login" className="d-flex flex-row mb-3">
              <Tab eventKey="login" title="Login">
                <LoginContainer
                  onSuccess={() => successOrchestrator('login')}
                  onCancel={onCancel}
                />
              </Tab>
              <Tab eventKey="signup" title="Signup">
                <SignUpContainer
                  onSuccess={() => successOrchestrator('signup')}
                />
              </Tab>
            </Tabs>
          </div>
        )}
      </div>
    </div>
  );
}

/**
 * Authentication Modal
 *
 * For the most part you should use {@link RequiresAuth} instead, to make sure a page requires the use to be logged in
 * first. This component can be used directly however if you want to provide another login button.
 *
 * @example
 * export default function MyPage() {
 *   const [showAuthModal, setShowAuthModal] = useState(false);
 *   return (
 *     <>
 *        // ...
 *        {showAuthModal && (
 *          <AuthenticationModal
 *            showModal={showAuthModal}
 *            onSuccess={() => setShowAuthModal(false)}
 *            onHide={() => setShowAuthModal(false)}
 *          />
 *        )}
 *     </>
 *   );
 * }
 */
export default function AuthenticationModal({
  onHide,
  showModal,
  onSuccess,
}: AuthContainerProps) {
  const [trackDone, setTrackDone] = useState(false);
  const { track } = useEventFan();

  useEffect(() => {
    if (trackDone) return;
    track('Login Modal Viewed');
    setTrackDone(true);
  }, [track, trackDone]);

  return (
    <>
      <Modal show={showModal} onHide={onHide || (() => undefined)} centered>
        <Modal.Header
          onClick={(e) => {
            e.stopPropagation();
          }}
          closeButton={!!onHide}
        >
          <Modal.Title>Log in or Sign up</Modal.Title>
        </Modal.Header>
        <Modal.Body
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <AuthenticateForm
            onSuccess={() => {
              track('Login Modal Success');
              onSuccess?.('login');
            }}
            onCancel={() => {
              track('Login Modal Cancelled');
              onHide?.();
            }}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}

interface SignUpContainerProps {
  onSuccess: () => void;
}

interface AuthContainerProps {
  authModalTitle?: string;
  onHide?: () => void;
  showModal: boolean;
  onSuccess?: (action: 'login' | 'signup') => void;
}

interface LoginContainerProps {
  onSuccess: () => void;
  onCancel?: () => void;
}

export interface CognitoTokenPayload {
  'cognito:groups': string[];
  'cognito:username': string;
  email: string;
  email_verified: boolean;
  family_name: string;
  given_name: string;
  hostRoles?: string;
  userID: string;
}
