import { ApolloLink } from '@apollo/client';
import { AuthOptions, createAuthLink } from 'aws-appsync-auth-link';
import config from '../../../config';

/**
 * Appsync Auth Link
 */
export default function appsyncAuthLink(auth: AuthOptions): ApolloLink {
  return createAuthLink({
    auth,
    url: config.Appsync.endpoint,
    region: config.Appsync.region,
  });
}
