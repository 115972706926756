import { useState } from 'react';
import { Alert, Form } from 'react-bootstrap';
import { Auth } from '@aws-amplify/auth';
import { authenticatedClient } from '@website/libs/graphql/apollo';
import { BackupPasswordDocument } from '@website/types/graphqlOperations';
import { SubmitButton } from '@website/components/forms/fields/SubmitButton';
import { useForm } from 'react-hook-form';

/**
 * New password submit handler
 */
export async function newPasswordSubmitHandler(
  { password }: NewPasswordFields,
  email: string,
  currentPassword: string,
  onSuccess: () => void,
  setError: (msg: string) => void,
) {
  try {
    // We have to try signing in again with the current password so that we can get the user object
    const user = await Auth.signIn(email, currentPassword);
    await Auth.completeNewPassword(user, password, {
      email,
    });
    await Auth.signIn(email, password);

    // Backup the user password. Note we need to create a client here directly, as the <AppsyncClient> from _app.ts
    // won't have had it's state updated yet, so it will still be set as unauthenticated.
    const client = authenticatedClient();
    await client.mutate({
      mutation: BackupPasswordDocument,
      variables: { input: { password } },
    });

    onSuccess();
  } catch (e) {
    setError(e.message);
  }
}

/**
 * New Password Required Form
 */
export default function NewPasswordRequiredForm({
  email,
  currentPassword,
  onSuccess,
}: NewPasswordRequiredFormProps) {
  const [error, setError] = useState<string>();

  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<NewPasswordFields>({
    defaultValues: {
      password: '',
    },
  });

  return (
    <>
      <h2>Please set a new password.</h2>
      <Alert variant="info">
        Your current password has expired - please set a new password.
      </Alert>

      <Form
        onSubmit={handleSubmit((v) =>
          newPasswordSubmitHandler(
            v,
            email,
            currentPassword,
            onSuccess,
            setError,
          ),
        )}
      >
        {error && <Alert variant="danger">{error}</Alert>}

        <Form.Label htmlFor="newpassword">New Password</Form.Label>
        <Form.Control
          autoComplete="new-password"
          id="newpassword"
          // Length min/max from https://docs.aws.amazon.com/cognito/latest/developerguide/limits.html#resource-quotas
          minLength={6}
          maxLength={99}
          required
          {...register('password')}
          type="password"
        />

        <div className="d-grid gap-2">
          <SubmitButton isSubmitting={isSubmitting} />
        </div>
      </Form>
    </>
  );
}

export interface NewPasswordRequiredFormProps {
  email: string;
  currentPassword: string;
  onSuccess: () => void;
}

export interface NewPasswordFields {
  password: string;
}
