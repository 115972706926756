import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faTiktok,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';
import { faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateTime } from 'luxon';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useContext, useMemo } from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { Store } from '../../Store';
import currencies from '../../dataObjects/currencies';
import ContactUsLink from '../help/ContactUsLink';

function CurrencySelector() {
  // State handling
  const { state, dispatch } = useContext(Store);

  function handleChange(e: React.ChangeEvent<HTMLSelectElement>) {
    dispatch({ type: 'SET_PREFERRED_CURRENCY', payload: e.target.value });
  }

  // Setup options
  const options = Object.keys(currencies).map((currencyIso) => (
    <option value={currencyIso} key={currencyIso}>
      {currencyIso} ({currencies[currencyIso].symbol_native})
    </option>
  ));

  // Select box
  return (
    <Form.Select
      value={state.preferred_currency}
      onChange={handleChange}
      className="bg-transparent mb-3 w-50"
    >
      {options}
    </Form.Select>
  );
}

export default function Footer() {
  const { state } = useContext(Store);

  const { pathname } = useRouter();

  const isTripsPage = pathname === '/trips/[tripUrl]';

  const { year } = DateTime.now().toUTC();

  const activities = useMemo(
    () => [
      {
        name: 'Climbing',
        slug: '/go/climbing',
      },
      {
        name: 'Hiking',
        slug: '/go/hiking',
      },
      {
        name: 'Kayaking and Canoeing',
        slug: '/go/kayaking-and-canoeing',
      },
      {
        name: 'Mixed Activity',
        slug: '/go/mixed-activity',
      },
      {
        name: 'Mountain Summits',
        slug: '/go/mountain-summits',
      },
      {
        name: 'Surfing & Coastal',
        slug: '/go/surfing-and-coastal',
      },
      {
        name: 'Winter Activities',
        slug: '/go/winter-activities',
      },
    ],
    [],
  );

  const bucketList = useMemo(
    () =>
      [
        {
          name: 'Aconcagua',
          slug: '/trips/aconcagua',
        },
        {
          name: 'Climb Kilimanjaro',
          slug: '/go/climb-kilimanjaro',
        },
        {
          name: 'Everest Base Camp',
          slug: '/go/everest-base-camp',
        },
        {
          name: 'Machu Picchu',
          slug: '/go/machu-picchu',
        },
        {
          name: 'Three Peaks Challenge',
          slug: '/go/3-peaks-challenge-uk',
        },
        {
          name: 'Tour du Mont Blanc',
          slug: '/trips/tour-du-mont-blanc-highlights',
        },
        {
          name: 'Patagonia',
          slug: '/go/patagonia',
        },
      ].sort((a, b) => a.name.localeCompare(b.name)),
    [],
  );

  const destinations = useMemo(
    () =>
      [
        {
          name: 'Albania',
          slug: 'albania',
        },
        {
          name: 'Argentina',
          slug: 'argentina',
        },
        {
          name: 'Greece',
          slug: 'greece',
        },
        {
          name: 'Jordan',
          slug: 'jordan',
        },
        {
          name: 'Morocco',
          slug: 'morocco',
        },
        {
          name: 'Nepal',
          slug: 'nepal',
        },
        {
          name: 'Norway',
          slug: 'norway',
        },
        {
          name: 'Peru',
          slug: 'peru',
        },
        {
          name: 'Chile',
          slug: 'chile',
        },
        {
          name: 'Spain',
          slug: 'spain',
        },
        {
          name: 'Sweden',
          slug: 'sweden',
        },
        {
          name: 'Switzerland',
          slug: 'switzerland',
        },
        {
          name: 'Tanzania',
          slug: 'tanzania',
        },
        {
          name: 'Thailand',
          slug: 'thailand',
        },
        {
          name: 'United Kingdom',
          slug: 'united-kingdom',
        },
        {
          name: 'Italy',
          slug: 'italy',
        },
        {
          name: 'South Africa',
          slug: 'south-africa',
        },
        {
          name: 'United States',
          slug: 'united-states',
        },
        {
          name: 'Ecuador',
          slug: 'ecuador',
        },
      ].sort((a, b) => a.name.localeCompare(b.name)),
    [],
  );

  return (
    <footer className="footer d-print-none mt-4">
      <div className="pt-6 pb-2 bg-gray-200 text-muted">
        <Container>
          <Row>
            <Col lg={3} md={6} className="mb-5 mb-lg-0">
              <div className="fw-bold text-uppercase text-dark mb-3">
                Skyhook
              </div>

              <p>Adventures with local guides.</p>

              {/*  <InstallPWAButton variant="muted" className="mb-3" /> */}

              <ul className="list-inline h5">
                <li className="list-inline-item">
                  <a
                    href="https://www.facebook.com/skyhookadventure"
                    target="_blank"
                    title="Facebook - Skyhook"
                    className="text-muted text-hover-primary"
                    rel="noreferrer"
                  >
                    <FontAwesomeIcon icon={faFacebook} />
                  </a>
                </li>

                <li className="list-inline-item">
                  <a
                    href="https://www.instagram.com/skyhookadventure/"
                    target="_blank"
                    title="Instagram - Skyhook"
                    className="text-muted text-hover-primary"
                    rel="noreferrer"
                  >
                    <FontAwesomeIcon icon={faInstagram} />
                  </a>
                </li>

                <li className="list-inline-item">
                  <a
                    href="https://x.com/heyskyhook"
                    target="_blank"
                    title="X - Skyhook"
                    className="text-muted text-hover-primary"
                    rel="noreferrer"
                  >
                    <FontAwesomeIcon icon={faX} />
                  </a>
                </li>

                <li className="list-inline-item">
                  <a
                    href="https://www.linkedin.com/company/skyhookadv/"
                    target="_blank"
                    title="Linkedin - Skyhook"
                    className="text-muted text-hover-primary"
                    rel="noreferrer"
                  >
                    <FontAwesomeIcon icon={faLinkedin} />
                  </a>
                </li>

                <li className="list-inline-item">
                  <a
                    href="https://www.youtube.com/channel/UCSYSUNJAMiiHuYowFsyCXkA"
                    target="_blank"
                    title="YouTube - Skyhook"
                    className="text-muted text-hover-primary"
                    rel="noreferrer"
                  >
                    <FontAwesomeIcon icon={faYoutube} />
                  </a>
                </li>

                <li className="list-inline-item">
                  <a
                    href="https://www.tiktok.com/@skyhook.adventure"
                    target="_blank"
                    title="TikTok - Skyhook"
                    className="text-muted text-hover-primary"
                    rel="noreferrer"
                  >
                    <FontAwesomeIcon icon={faTiktok} />
                  </a>
                </li>
              </ul>

              {state.preferred_currency ? (
                <>
                  <Col sm={12} className="mb-5 mb-lg-0">
                    <div className="fw-bold text-uppercase text-dark mt-3 mb-3">
                      Payments
                    </div>
                    <CurrencySelector />
                  </Col>
                </>
              ) : null}

              <div className="fw-bold text-uppercase text-dark mt-3 mb-3">
                Book with confidence
              </div>

              <p>
                All bookings processed through this website are financially
                protected by Trust My Travel. Membership number TMT51612.
              </p>
              {/* <ul className="list-inline" style={{ fontSize: 15 }}>
                <li className="list-inline-item">
                  <FontAwesomeIcon icon={faCcVisa} />
                </li>
                <li className="list-inline-item">
                  <FontAwesomeIcon icon={faCcMastercard} />
                </li>
                <li className="list-inline-item">
                  <FontAwesomeIcon icon={faCcDiscover} />
                </li>
                <li className="list-inline-item">
                  <FontAwesomeIcon icon={faCcDinersClub} />
                </li>
                <li className="list-inline-item">
                  <FontAwesomeIcon icon={faCcAmex} />
                </li>
              </ul> */}

              <div className="fw-bold text-uppercase text-dark mt-3 mb-3">
                Trustpilot Reviews
              </div>

              <p>
                We're rated Excellent on Trustpilot.
                <br />
                Read{' '}
                <a
                  href="https://www.trustpilot.com/review/skyhookadventure.com"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  our reviews
                </a>
                .
              </p>
            </Col>

            <Col lg={3} md={6} className="mb-5 mb-lg-0">
              <div className="fw-bold text-uppercase text-dark mb-3">
                Popular Destinations
              </div>

              <ul className="list-unstyled">
                {destinations.map(({ name, slug }) => (
                  <li key={slug}>
                    <Link href={`/go/${slug}`} className="text-muted">
                      {name}
                    </Link>
                  </li>
                ))}
              </ul>

              <ul className="list-unstyled">
                <li>
                  <Link href="/trips" className="text-muted">
                    All Trips
                  </Link>
                </li>
              </ul>
            </Col>

            <Col lg={3} md={6} className="mb-5 mb-lg-0">
              <div className="fw-bold text-uppercase text-dark mb-3">
                Popular Activities
              </div>

              <ul className="list-unstyled">
                {activities.map(({ name, slug }) => (
                  <li key={slug}>
                    <Link href={slug} className="text-muted">
                      {name}
                    </Link>
                  </li>
                ))}
              </ul>

              <div className="fw-bold text-uppercase text-dark mb-3 mt-3">
                Bucket List
              </div>

              <ul className="list-unstyled">
                {bucketList.map(({ name, slug }) => (
                  <li key={slug}>
                    <Link href={slug} className="text-muted">
                      {name}
                    </Link>
                  </li>
                ))}
              </ul>

              <div className="fw-bold text-uppercase text-dark mb-3">
                Latest Deals
              </div>

              <ul className="list-unstyled">
                <li>
                  <Link href={`/deals`} className="text-muted">
                    On Sale Now
                  </Link>
                </li>
              </ul>
            </Col>

            <Col lg={3} md={6} className="mb-5 mb-lg-0">
              <div className="fw-bold text-uppercase text-dark mb-3">About</div>

              <ul className="list-unstyled">
                <li>
                  <Link href="/about" className="text-muted">
                    About
                  </Link>
                </li>

                <li>
                  <ContactUsLink
                    helpComponent={({ onClick }) => (
                      <a href="#" onClick={onClick} className="text-muted">
                        Contact
                      </a>
                    )}
                  />
                </li>

                <li>
                  <Link href="/blog" className="text-muted">
                    Blog
                  </Link>
                </li>

                <li>
                  <Link href="/affiliates" className="text-muted">
                    Affiliates
                  </Link>
                </li>

                <li>
                  <Link
                    href="https://status.skyhookadventure.com/"
                    target="_blank"
                    className="text-muted"
                  >
                    Service Status
                  </Link>
                </li>
              </ul>

              <div className="fw-bold text-uppercase text-dark mb-3 mt-3">
                Customers
              </div>

              <ul className="list-unstyled">
                <li>
                  <Link href="/customer-faqs" className="text-muted">
                    Customer FAQs
                  </Link>
                </li>
                <li>
                  <Link href="/booking-terms" className="text-muted">
                    Booking Terms
                  </Link>
                </li>

                <li>
                  <Link href="/terms-of-use" className="text-muted">
                    Terms of Use
                  </Link>
                </li>

                <li>
                  <Link href="/privacy-policy" className="text-muted">
                    Privacy Policy
                  </Link>
                </li>
              </ul>

              <div className="fw-bold text-uppercase text-dark mb-3 mt-3">
                Hosts
              </div>

              <ul className="list-unstyled">
                <li>
                  <Link href="/apply-host" className="text-muted">
                    Apply to Host
                  </Link>
                </li>
                <li>
                  <Link href="/host-terms" className="text-muted">
                    Host Terms
                  </Link>
                </li>
                <li>
                  <Link href="/host-faqs" className="text-muted">
                    Host FAQs
                  </Link>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="py-4 fw-light bg-gray-800 text-gray-300">
        <Container
          style={{
            ...(isTripsPage ? { paddingBottom: 62 } : {}),
          }}
        >
          <Row>
            <Col md="12">
              <p className="text-sm mb-md-0">
                ©{year}. Skyhook Adventure Limited. All rights reserved.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </footer>
  );
}
