/*
 * @file
 * Global State Store
 *
 * Similar to Redux - see https://medium.com/octopus-labs-london/replacing-redux-with-react-hooks-and-context-part-1-11b72ffdb533
 */

import React, { ReactNode } from 'react';
import { LegacyUser } from './types/LegacyUser';

/**
 * Initial store state (where no auth credentials have been provided)
 */
const initialState = {
  isAuthenticated: false,
  isAuthenticating: true,
  user: {
    cognito_identity_id: null,
    cognito_id: null,
    first_name: null,
    last_name: null,
    email: null,
    phone: null,
    id: null,
    roles: [],
    host_roles: [],
  },
  preferred_currency: null,
  cachedScripts: [], // Used to prevent re-loading of scripts
};

/**
 * Global Store
 *
 * Context object for global store
 *
 * @example
 * // Returns <h1>Hello NAME</h1>
 * const { state, dispatch } = useContext(Store);
 * return (<h1>Welcome {state.user.first_name}</h1>);
 *
 */
export const Store = React.createContext({
  state: initialState,
  dispatch: (() => undefined) as React.Dispatch<any>,
});

function reducer(state, action) {
  switch (action.type) {
    case 'SET_AUTHENTICATING':
      return { ...state, isAuthenticating: action.payload };
    case 'SET_AUTHENTICATED':
      return { ...state, isAuthenticated: action.payload };
    case 'SET_SIGNED_OUT':
      return {
        ...state,
        isAuthenticated: false,
        isAuthenticating: false,
        user: { ...initialState.user },
      };
    case 'SET_USER':
      return {
        ...state,
        isAuthenticating: false,
        isAuthenticated: true,
        user: { ...initialState.user, ...action.payload },
      };
    case 'SET_DEFAULT_CURRENCY':
      return {
        ...state,
        // Keep the preferred currency if already set
        preferred_currency: state.preferred_currency
          ? state.preferred_currency
          : action.payload,
      };
    case 'SET_PREFERRED_CURRENCY':
      return {
        ...state,
        preferred_currency: action.payload,
      };
    case 'ADD_CACHED_SCRIPT':
      return {
        ...state,
        cachedScripts: [...state.cachedScripts, action.payload],
      };
    case 'REMOVE_CACHED_SCRIPT':
      return {
        ...state,
        cachedScripts: state.cachedScripts.filter((i) => i !== action.payload),
      };
    default:
      return state;
  }
}

/**
 * Global Data Store Provider
 *
 * This should only be used once in _app, or in testing where the global context is needed.
 */
export function StoreProvider({
  children,
  user = undefined,
}: StoreProviderProps) {
  const [state, dispatch] = React.useReducer(reducer, {
    ...initialState,
    // Set the user state from props passed down by SSR, if possible
    user: user || initialState.user,
    isAuthenticated: !!user,
    isAuthenticating: !user,
  });
  const value = { state, dispatch };

  return <Store.Provider value={value}>{children}</Store.Provider>;
}

export interface StoreProviderProps {
  children: ReactNode;

  /**
   * User from SSR
   *
   * This sets the user state directly if available
   */
  user?: LegacyUser;
}
