import config from '@website/config';
import useLocation from '@website/hooks/useLocation';
import { CountryCodes } from '@website/types/graphqlOperations';
import { Drip, FacebookPixel, GA4, Posthog, useEventFan } from 'eventfan';
import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import { Button, Form, Table } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';

type CookieConsentStatus = 'accept_all' | 'deny_all' | 'not_set';

const consentStatusCookieName = 'cookie-consent-status';

/**
 * Cookie consent countries
 *
 * Includes all EU countries, the UK and the US (for California).
 */
export const cookieConsentCountries = [
  CountryCodes.AT,
  CountryCodes.BE,
  CountryCodes.BG,
  CountryCodes.CY,
  CountryCodes.CH,
  CountryCodes.CZ,
  CountryCodes.DE,
  CountryCodes.DK,
  CountryCodes.EE,
  CountryCodes.ES,
  CountryCodes.FI,
  CountryCodes.FR,
  CountryCodes.GB,
  CountryCodes.GR,
  CountryCodes.HR,
  CountryCodes.HU,
  CountryCodes.IE,
  CountryCodes.IS,
  CountryCodes.IT,
  CountryCodes.LI,
  CountryCodes.LT,
  CountryCodes.LU,
  CountryCodes.LV,
  CountryCodes.MT,
  CountryCodes.NL,
  CountryCodes.NO,
  CountryCodes.PL,
  CountryCodes.PT,
  CountryCodes.RO,
  CountryCodes.SE,
  CountryCodes.SI,
  CountryCodes.SK,
  CountryCodes.US,
];

/**
 * Cookie Consent Modal Form
 *
 * Contains the form for setting cookie consent.
 */
export function CookieConsentModal({
  setCookieConsentStatus,
}: {
  setCookieConsentStatus: (status: CookieConsentStatus) => void;
}) {
  // State for showing the detailed settings table
  const [showManage, setShowManage] = useState<boolean>(false);

  // State for consent within the detailed settings table
  const [marketingConsent, setMarketingConsent] = useState<boolean>(false);

  return (
    <>
      {/* Enable background scrolling still */}
      <style jsx global>
        {`
          .modal-open {
            overflow-y: auto !important;
          }
        `}
      </style>
      <Modal
        show={true}
        centered
        id="cookie-consent"
        enforceFocus={false}
        keyboard={false}
        scrollable
        backdropClassName="opacity-25"
      >
        <Modal.Header className="border-0 pb-0">
          <Modal.Title className="h5">Cookies & Your Privacy</Modal.Title>
        </Modal.Header>

        <Modal.Body className="pt-1 pb-1 small">
          <p className="pt-3">
            We use cookies (and similar technologies) to improve your experience
            on our site and monitor our advertising.
          </p>

          {/* Manage text */}
          {showManage && (
            <>
              <p>You can manage your preferences with the table below:</p>

              <Table size="sm">
                <thead>
                  <tr>
                    <td>Permit</td>
                    <td>Purpose</td>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td>
                      <Form.Label
                        className="visually-hidden"
                        htmlFor="marketing-consent"
                      >
                        Marketing and Analytics
                      </Form.Label>
                      <Form.Check
                        type="switch"
                        checked={marketingConsent}
                        onChange={() => setMarketingConsent(!marketingConsent)}
                        id="marketing-consent"
                      />
                    </td>
                    <td>
                      <h6>Marketing and Analytics</h6>
                      <p>
                        These help gain insights into user behaviour, enabling
                        us to offer you a more tailored browsing experience and
                        customize the content on our website. They also help us
                        monitor and personalize our marketing.
                      </p>
                      <p>
                        For instance, we gather data on the pages you access to
                        better deliver content that is pertinent to your
                        interests.
                      </p>
                      <p>
                        The specific tools used here are: Posthog, Drip, Meta
                        Ads, Google Analytics and Sentry.
                      </p>
                    </td>
                  </tr>

                  <tr>
                    <td>NA</td>
                    <td>
                      <h6>Essential</h6>
                      <p>
                        We utilise browser cookies that are crucial for the
                        proper functioning of our website. For example, we save
                        your data collection preferences to ensure we respect
                        them when you revisit our site. You can deactivate these
                        cookies in your browser settings, but be aware that
                        doing so may cause the site to malfunction.
                      </p>
                      <p>
                        Please note that essential cookies are not possible to
                        disable here (and they are permitted under cookie
                        consent laws).
                      </p>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </>
          )}
        </Modal.Body>

        <Modal.Footer className="border-0 pt-0">
          <Button variant="light" onClick={() => setShowManage(!showManage)}>
            {showManage ? 'Back' : 'Manage'}
          </Button>

          {showManage && (
            <Button
              className="px-4 ms-2"
              onClick={() =>
                setCookieConsentStatus(
                  marketingConsent ? 'accept_all' : 'deny_all',
                )
              }
            >
              Save {marketingConsent ? '' : '(deny all)'}
            </Button>
          )}

          {!showManage && (
            <Button
              variant="success"
              className="px-4 ms-2"
              onClick={() => setCookieConsentStatus('accept_all')}
            >
              Accept all
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}

/**
 * Cookie consent
 */
export default function CookieConsent({ reset }: { reset?: boolean }) {
  const { addDestination } = useEventFan();

  const [cookieConsentStatus, setCookieConsentStatus] =
    useState<CookieConsentStatus>();

  const { location } = useLocation();

  // Check the status cookie (persists cookie consent state)
  useEffect(() => {
    // Only run once we have a country code
    if (location?.country) {
      // If not in a cookie consent region, just set as consented
      if (!cookieConsentCountries.includes(location.country)) {
        setCookieConsentStatus('accept_all');
      }

      // Otherwise check the cookie
      else {
        try {
          // Remove the cookie if reset is true
          if (reset) {
            Cookies.remove(consentStatusCookieName);
          }

          const currentStatus = Cookies.get(consentStatusCookieName);

          if (currentStatus) {
            setCookieConsentStatus(currentStatus);
          } else {
            setCookieConsentStatus('not_set');
          }
        } catch (_e) {
          // If cookies are disabled, set a default to avoid showing the modal
          // on every page load
          setCookieConsentStatus('accept_all');
        }
      }
    }
  }, [reset, location]);

  // Handle status changes
  useEffect(() => {
    // Add destinations if the user has given consent
    if (cookieConsentStatus === 'accept_all') {
      addDestination(new Posthog({ teamApiKey: config.posthog }));
      addDestination(new Drip({ accountId: config.drip.dripID }));
      addDestination(new FacebookPixel({ pixelId: config.Facebook.pixelId }));
      addDestination(new GA4({ measurementId: config.Google.trackingId }));

      // permit ads
      gtag('consent', 'update', {
        ad_user_data: 'granted',
        ad_personalization: 'granted',
        ad_storage: 'granted',
        analytics_storage: 'granted',
      });
    }

    // Set the cookie if a user has specified their preferences
    if (
      cookieConsentStatus === 'accept_all' ||
      cookieConsentStatus === 'deny_all'
    ) {
      Cookies.set(consentStatusCookieName, cookieConsentStatus, {
        expires: 365, // 1 year
      });
    }
  }, [cookieConsentStatus, addDestination]);

  // Show a popup if the user has not set their consent status
  if (cookieConsentStatus === 'not_set') {
    return (
      <CookieConsentModal setCookieConsentStatus={setCookieConsentStatus} />
    );
  }

  return null;
}
