/* eslint-disable no-nested-ternary */
import { Store } from '@website/Store';
import { useRouter } from 'next/navigation';
import { useCallback, useContext, useState } from 'react';
import AuthenticationModal from '../auth/AuthenticationModal';

type AskForHelpProps = {
  helpComponent: React.FC<{
    onClick: (e: React.MouseEvent<HTMLElement>) => void;
  }>;
};

export default function ContactUsLink({
  helpComponent: HelpComponent,
}: AskForHelpProps) {
  const { push } = useRouter();

  const { state } = useContext(Store);
  const [showAuthModal, setShowAuthModal] = useState(false);

  const isUserLoggedIn = !!state.user.id;

  const handleAskForHelp = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      e.preventDefault();
      if (isUserLoggedIn) {
        push(`/help/contact`);
      } else {
        setShowAuthModal(true);
      }
    },
    [isUserLoggedIn, push],
  );

  return (
    <>
      <HelpComponent onClick={handleAskForHelp} />
      {showAuthModal && (
        <AuthenticationModal
          showModal={showAuthModal}
          onSuccess={async () => {
            // If user is logged in, redirect to the link
            push(`/help/contact`);
            setShowAuthModal(false);
          }}
          onHide={() => setShowAuthModal(false)}
        />
      )}
    </>
  );
}
