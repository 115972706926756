import { useQuery } from '@tanstack/react-query';
import { PromotionalBannerInfo } from '@website/pages/api/banners/promotions';
import { Button, Container, Nav, Navbar } from 'react-bootstrap';
import styles from './PromotionalBanner.module.scss';

export default function PromotionalBanner() {
  const { isLoading, data } = useQuery<PromotionalBannerInfo | null>({
    queryKey: ['promotionalBanner'],
    queryFn: async () => {
      console.log('fetching promotional banner');
      const response = await fetch('/api/banners/promotions');
      return response.json();
    },
  });

  if (isLoading || !data) {
    return null;
  }

  return (
    <>
      <Navbar
        className={`border-bottom text-white ${styles.bannerContainer}`}
        id="book-now-navbar"
      >
        <Container>
          <Nav className="mx-auto">
            <div className="d-flex flex-column justify-content-center p-2">
              <div className="w-100">{data.text}</div>
            </div>
            <div className="d-flex flex-column px-md-5 justify-content-center">
              <Button
                as="a"
                variant="light"
                className="text-nowrap"
                href={data.url}
                rel="noopener noreferrer"
                target="_blank"
                size="sm"
              >
                {data.buttonText}
              </Button>
            </div>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
}
