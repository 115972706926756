/* eslint-disable no-use-before-define */
import { useEffect, useState } from 'react';
import { Button, Alert, Form, AlertProps } from 'react-bootstrap';
import { Auth } from '@aws-amplify/auth';
import { useForm } from 'react-hook-form';
import { SubmitButton } from '@website/components/forms/fields/SubmitButton';

/**
 * Email verification submit handler
 */
export async function submitEmailVerificationForm(
  { email, code }: EmailVerificationFields,
  onSuccess: EmailVerificationProps['onSuccess'],
  setAlert: (props: AlertProps) => void,
  password?: string,
) {
  try {
    // Verify the email
    await Auth.confirmSignUp(email, code);

    // Login if the password is set
    if (password) {
      await Auth.signIn(email, password);
    }

    onSuccess();
  } catch (verifyError) {
    if (
      verifyError.code === 'NotAuthorizedException' &&
      verifyError.message ===
        'User cannot be confirmed. Current status is CONFIRMED'
    ) {
      // Handle already authorized (just sign in)
      onSuccess();
    } else if (verifyError.code === 'ExpiredCodeException') {
      // Handle code expired (send a new one)
      try {
        await Auth.resendSignUp(email);

        setAlert({
          variant: 'warning',
          children:
            'Your verification code has expired - we have just emailed you a new one.',
        });
      } catch (resendError) {
        setAlert({ children: resendError.message, variant: 'danger' });
      }
    } else {
      // Otherwise set the error message
      setAlert({ variant: 'danger', children: verifyError.message });
    }
  }
}

/**
 * Resend the verification code, on user request
 */
export async function resendVerificationEmail(
  email: string,
  setAlert: (props: AlertProps) => void,
) {
  try {
    await Auth.resendSignUp(email);
    setAlert({
      children:
        'A new code has been sent to your email address - please check there.',
      variant: 'info',
    });
  } catch (e) {
    setAlert({ children: e.message, variant: 'danger' });
  }
}

/**
 * Email Verification Form
 *
 * Used to verify an email address with a code that has been emailed to the user.
 */
export default function EmailVerificationForm({
  code,
  email,
  password,
  onSuccess,
}: EmailVerificationProps) {
  // Alert state (for e.g. error messages)
  const [alertProps, setAlert] = useState<AlertProps>();

  // Submit immediately if the code/email are provided in props (e.g. from the url)
  useEffect(() => {
    if (email && code) {
      submitEmailVerificationForm(
        { email, code },
        onSuccess,
        setAlert,
        password,
      );
    }
  }, [email, code, onSuccess, password]);

  const {
    register,
    handleSubmit,
    watch,
    formState: { isSubmitting },
  } = useForm<EmailVerificationFields>({
    defaultValues: {
      email,
      code,
    },
  });

  return (
    <>
      <h1>Verify Your Email</h1>

      {alertProps && <Alert {...alertProps} />}

      <Form
        autoComplete="off"
        onSubmit={handleSubmit((v) =>
          submitEmailVerificationForm(v, onSuccess, setAlert, password),
        )}
      >
        {/* Don't show the email field if it is in the props */}
        {!email && (
          <Form.Group controlId="email">
            <Form.Label>Email</Form.Label>
            <Form.Control type="email" required {...register('email')} />
          </Form.Group>
        )}

        <Form.Group controlId="code">
          <Form.Label>Code</Form.Label>
          <Form.Control {...register('code')} autoComplete="off" required />
          <Form.Text className="text-muted">
            We've sent this code to your email address {email}.
          </Form.Text>
        </Form.Group>

        <div className="d-grid gap-2">
          <SubmitButton isSubmitting={isSubmitting} />
        </div>
      </Form>

      <div className="d-grid gap-2">
        <Button
          variant="outline-primary"
          onClick={() => resendVerificationEmail(watch('email'), setAlert)}
          className="my-1"
        >
          Re-send code
        </Button>
      </div>
    </>
  );
}

/**
 * Required Fields to verify an email address with Cognito
 */
export interface EmailVerificationFields {
  email: string;
  code: string;
}

interface EmailVerificationProps {
  code?: string;
  email?: string;
  password?: string;
  onSuccess: () => void;
}
