/* eslint-disable @typescript-eslint/no-shadow */
import {
  ApolloLink,
  Operation,
  NextLink,
  Observable,
  Observer,
  FetchResult,
  ApolloClient,
} from '@apollo/client';
import { useEffect } from 'react';
import { openDB, DBSchema, IDBPDatabase } from 'idb';
import { v4 as uuid } from 'uuid';

/**
 * GraphQL request type
 */
type RequestType = 'query' | 'mutation' | 'subscription';

/**
 * Failed request to store in the database
 */
interface FailedRequestForDB {
  date: Date;
  id: string;
  operation: Operation;
  temporaryCacheID?: string;
}

/**
 * Failed request to store in memory
 *
 * We include forward and observer here, so that where possible we can send the mutation on using the original link
 * chain. This means that if e.g. there was a cache `update` function afterwards, that would still run.
 */
type FailedRequestForTemporaryStorage = FailedRequestForDB & {
  forward?: NextLink;
  observer?: Observer<FetchResult>;
};

/**
 * IndexedDB Database Schema
 *
 * https://www.npmjs.com/package/idb#Typescript
 */
interface LocalDatabaseSchema extends DBSchema {
  mutations: {
    key: string;
    value: FailedRequestForDB;
  };
}

/**
 * ## Offline Mutation Link
 *
 * If the internet connection is lost, this link will buffer up any mutations that have `optimisticResponse` set, and
 * then replay them once the connection is restored. It uses persistent storage (IndexedDB) so that mutations can be
 * resumed between sessions.
 *
 * ### Setup
 *
 *  1. Add to your client links (must be before e.g. `HttpLink`).
 *  2. Add an event handler to call `setIsOnline` whenever a connection is available (use `useUpdateConnectionStatus` in
 *     react).
 */
export default class OfflineMutationLink extends ApolloLink {
  constructor({ databaseName = 'offlineGraphQL' } = {}) {
    super();

    this.databaseName = databaseName;
  }

  private databaseName: string;

  private database?: IDBPDatabase<LocalDatabaseSchema>;

  /**
   * IndexedDB database
   *
   * If IndexedDB support is not available, this will be undefined. Note also it is a promise that resolves to the
   * database, as it may not be ready yet.
   */
  private async getDatabase(): Promise<
    IDBPDatabase<LocalDatabaseSchema> | undefined
  > {
    if (this.database) return Promise.resolve(this.database);

    try {
      const db = await openDB<LocalDatabaseSchema>(this.databaseName, 1, {
        upgrade(db) {
          db.createObjectStore('mutations', {
            keyPath: 'id',
          });
        },
      });

      return db;
    } catch (e) {
      // Fail silently if IndexedDB is not supported
    }

    return undefined;
  }

  /**
   * Temporary storage
   */
  private temporaryStorage: FailedRequestForTemporaryStorage[] = [];

  /**
   * Store a failed mutation
   *
   * Note: Don't wait for this, as it may in turn wait for the database to be initialised.
   */
  private async storeFailedMutation(
    operation: Operation,
    forward: NextLink,
    observer: Observer<FetchResult>,
  ): Promise<void> {
    // Prepare the failed request object
    const id = uuid();
    const context = operation.getContext();
    const temporaryCacheID = context.cache.identify(context.optimisticResponse);
    const failedRequest: FailedRequestForDB = {
      id,
      operation,
      temporaryCacheID,
      date: new Date(),
    };

    // Store in persistent storage first (to prevent a race with temporary storage on recover)
    const db = await this.getDatabase();
    await db?.put('mutations', failedRequest);

    // Also store in temporary storage
    this.temporaryStorage.push({
      ...failedRequest,
      forward,
      id,
      observer,
    });
  }

  /**
   * Apollo link request handler
   */
  public request(operation: Operation, forward: NextLink) {
    // Get the connection status
    const isOnline = navigator?.onLine !== false; // Handles the edge case of `navigator.onLine` not supported

    // Get some details about the request
    const { optimisticResponse } = operation.getContext();
    const requestType = (operation.query.definitions[0] as any)
      .operation as RequestType;

    // Persist offline mutations with an optimistic response
    if (!isOnline && requestType === 'mutation' && optimisticResponse) {
      return new Observable<FetchResult>((observer: Observer<FetchResult>) => {
        // Store the failed request
        this.storeFailedMutation(operation, forward, observer);

        // Mark the mutation as complete so the UI can update (and we skip any links after this one e.g. `HttpLink`)
        observer.next({ data: optimisticResponse });
        observer.complete();
      });
    }

    // Default to otherwise just passing on to the next link
    return forward(operation);
  }

  /**
   * Retry all failed mutations
   */
  private async retryMutations(client: ApolloClient<any>) {
    const db = await this.getDatabase();

    // Do the temporary ones first
    const temporaryStorageReplays = this.temporaryStorage.map(
      async ({ id, operation, forward, observer, temporaryCacheID }) => {
        // Quick check of internet connectivity
        if (navigator?.onLine === false) return;

        // Delete first from the db
        await db?.delete('mutations', id);

        // Evict from the cache
        if (temporaryCacheID) {
          client.cache.evict({ id: temporaryCacheID });
        }

        // Now run
        forward(operation).subscribe(observer);
      },
    );
    await Promise.all(temporaryStorageReplays);

    // Now do any remaining persistent ones
    const failedRequests = await db?.getAllKeys('mutations');

    const persistentStorageReplays = failedRequests?.map(async (key) => {
      // Quick check of internet connectivity
      if (navigator?.onLine === false) return;

      // Try to get the item and delete it
      const tx = db?.transaction('mutations', 'readwrite');
      const request = await tx?.store.get(key);
      await tx?.store.delete(key);

      // If the item isn't available any more, just return as it has likely been handled by another instance
      if (!request) return;

      // Evict old item from the cache
      if (request.temporaryCacheID) {
        client.cache.evict({ id: request.temporaryCacheID });
      }

      // Check the mutation isn't stale
      const now = new Date().getSeconds();
      const sevenDaysAgo = now - 7 * 24 * 60 * 60;
      const isMoreThanSevenDaysAgo = sevenDaysAgo > request.date.getSeconds();
      if (isMoreThanSevenDaysAgo) return;

      // Retry the mutation
      client.mutate({
        mutation: request.operation.query,
        variables: request.operation.variables,
      });
    });
    await Promise.all(persistentStorageReplays || []);

    // Run garbage collection on the cache
    client.cache.gc();
  }

  public setIsOnline = (client) => {
    return this.retryMutations(client);
  };
}

/**
 * Hook to update the connection status for these links
 *
 * Must be added separately.
 */
export function useUpdateConnectionStatus(
  client: ApolloClient<any>,
  offlineMutationLink: OfflineMutationLink,
  skip?: boolean,
) {
  useEffect(() => {
    const setOnline = async () => {
      await offlineMutationLink.setIsOnline(client);
      client.reFetchObservableQueries(); // Also refetch any queries that are observable
    };

    if (!skip) {
      if (navigator.onLine !== false) offlineMutationLink.setIsOnline(client);
      window.addEventListener('online', setOnline);
    }

    return function cleanUp() {
      window.removeEventListener('online', setOnline);
    };
  }, [client, offlineMutationLink, skip]);
}
