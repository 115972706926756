import { Button, Alert } from 'react-bootstrap';
import config from '@website/config';
import { useEffect, useState } from 'react';
import { FaGoogle, FaFacebookSquare } from 'react-icons/fa';

export enum SocialLoginPlatform {
  GOOGLE = 'Google',
  FACEBOOK = 'Facebook',
}

/**
 * Create the oauth url for social login
 *
 * Note we can't use the Amplify helpers here as they assume the social login is done directly in the Cognito identity
 * pool, rather than the Cognito user pool (which we use so we can store extra details with the users).
 */
export function createSocialLoginOauthURL(platform: string) {
  const oauthDomain = config.awsAmplify.Auth.oauth.domain;
  const redirectURL = `${window.location.protocol}//${
    window.location.hostname +
    (window.location.port ? `:${window.location.port}` : '') // Add the port if specified (e.g. on localhost:3000)
  }/auth/callback`;
  const { responseType } = config.awsAmplify.Auth.oauth;
  const clientID = config.awsAmplify.Auth.userPoolWebClientId;
  const scopes = config.awsAmplify.Auth.oauth.scope.join(',');
  return `https://${oauthDomain}/oauth2/authorize?redirect_uri=${redirectURL}&response_type=${responseType}&client_id=${clientID}&identity_provider=${platform}&scopes=${scopes}`;
}

/**
 * Social Login Buttons
 *
 * When a social login button is clicked, it opens up the /auth/callback page in a popup (or new tab on mobile) which
 * allows the user to sign in with a social provider. When that login is complete an event is fired which can be picked
 * up in this main tab (with the {@link receiveMessage} handler) to continue with whatever needs to be done after login.
 */
export default function SocialLoginButtons({
  onSuccessCallback,
}: SocialLoginButtonsProps) {
  const [loginWindow, setLoginWindow] = useState<Window>(null);
  const [error, setError] = useState<string>();

  /**
   * Event handler for message events from the social login popup
   */
  function receiveMessage(event: SocialLoginMessageEvent) {
    if (event.data.source !== 'skyhook-social-login') {
      return;
    }
    if (event.data.success) {
      onSuccessCallback();
    } else {
      setError(event.data.errorMessage);
    }
  }

  // Remove the event handler on teardown
  useEffect(() => {
    return () => {
      window.removeEventListener('message', receiveMessage);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Social login button click handler
   */
  function socialLoginButtonClick(platform: SocialLoginPlatform) {
    if (!loginWindow || loginWindow.closed) {
      // In order to solve a Cognito bug (see @link handleAccountAlreadyExistsError) we need the opened
      // window to have access to the social login platform name (when the bug occurs).
      try {
        localStorage.setItem('socialLoginPlatform', platform);
      } catch (e) {
        // When localStorage is not available it will error out (as a security issue)
        // In this case we have to not try to solve the Cognito bug (which results
        // in users sometimes needing to sign in twice).
      }

      const redirectURL = createSocialLoginOauthURL(platform);

      const newLoginWindow = window.open(
        redirectURL,
        'SocialLoginWindow',
        'toolbar=no, menubar=no, width=600, height=700, top=100, left=100',
      );

      window.addEventListener('message', receiveMessage);
      setLoginWindow(newLoginWindow);
    } else {
      loginWindow.focus();
    }
  }

  return (
    <>
      {error && (
        <Alert variant="danger">
          <p>{error}</p>
        </Alert>
      )}
      <div className="d-grid gap-2">
        <Button
          onClick={() => socialLoginButtonClick(SocialLoginPlatform.FACEBOOK)}
          variant="primary"
        >
          <span className="me-2">
            <FaFacebookSquare size="20px" />
          </span>
          <span>Continue with Facebook</span>
        </Button>
        <Button
          onClick={() => socialLoginButtonClick(SocialLoginPlatform.GOOGLE)}
          variant="secondary"
        >
          <span className="me-2">
            <FaGoogle size="20px" />
          </span>
          <span>Continue with Google </span>
        </Button>
      </div>
    </>
  );
}

interface SocialLoginButtonsProps {
  onSuccessCallback: () => void;
}

interface SocialLoginMessageEvent {
  data: SocialLoginMessage;
}

export interface SocialLoginMessage {
  /**
   *
   * browser extensions can also use window.opener to send messages to the
   * parent window. We need to make sure we're passing the correct message from
   * our login window and not any other source (e.g. a browser extension).
   *
   */
  source: 'skyhook-social-login';
  success: boolean;
  errorMessage?: string;
}
