import { Amplify } from '@aws-amplify/core';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import CookieConsent from '@website/components/app/CookieConsent';
import ErrorBoundary from '@website/components/app/ErrorBoundary';
import ForegroundMessagesSetup from '@website/components/app/firebase/ForegroundMessagesSetup';
import NextNProgress from '@website/components/directoryTheme/NextNProgress';
import { AppsyncApolloProvider } from '@website/libs/graphql/apollo';
import { NextPage } from 'next';
import { AppProps } from 'next/app';
import SSRProvider from 'react-bootstrap/SSRProvider';
import AnalyticsSetup from '../components/app/AnalyticsSetup';
import AuthenticateUser from '../components/app/AuthenticateUser';
import SetupDefaultCurrency from '../components/app/SetupDefaultCurrency';
import DefaultLayout from '../components/layouts/DefaultLayout';
import MainLayout, { LayoutProps } from '../components/layouts/MainLayout';
import config from '../config';
import { StoreProvider } from '../Store';
import { LegacyUser } from '../types/LegacyUser';

// Directory Theme
import '@fortawesome/fontawesome-svg-core/styles.css';
import '../scss/style.default.scss';

// Custom styles
import './styles.css';

const queryClient = new QueryClient();

/*
 * Configure AWS Amplify
 */
Amplify.configure(config.awsAmplify);

/**
 * NextJS Custom App
 *
 * Adds some default components to all pages.
 * https://nextjs.org/docs/advanced-features/custom-app
 */
const App: NextPage<NewProps> = function App({ Component, pageProps }) {
  const Layout = Component.Layout || DefaultLayout;

  return (
    <QueryClientProvider client={queryClient}>
      <SSRProvider>
        <NextNProgress startPosition={0.3} stopDelayMs={0} />
        <StoreProvider>
          <AuthenticateUser>
            <ForegroundMessagesSetup />
            <AnalyticsSetup>
              <CookieConsent />
              <AppsyncApolloProvider>
                <SetupDefaultCurrency>
                  <MainLayout>
                    <Layout>
                      <ErrorBoundary>
                        <Component {...pageProps} />
                      </ErrorBoundary>
                    </Layout>
                  </MainLayout>
                </SetupDefaultCurrency>
              </AppsyncApolloProvider>
            </AnalyticsSetup>
          </AuthenticateUser>
        </StoreProvider>
      </SSRProvider>
    </QueryClientProvider>
  );
};

export default App;

/**
 * Declare type for all sub-components
 */
declare type NewProps = AppProps & {
  Component: {
    Layout(props: LayoutProps): JSX.Element;
  };
  userFromSSR?: LegacyUser;
  isServerSideRendered?: boolean;
};
