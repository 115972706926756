const currencies: {
  [currencyCode: string]: {
    code: string;
    decimal_digits: number;
    rounding: number;
    symbol: string;
    symbol_native: string;
  };
} = {
  AUD: {
    code: 'AUD',
    decimal_digits: 2,
    rounding: 0,
    symbol: 'AU$',
    symbol_native: '$',
  },
  BGN: {
    code: 'BGN',
    decimal_digits: 2,
    rounding: 0,
    symbol: 'BGN',
    symbol_native: 'лв.',
  },
  BRL: {
    code: 'BRL',
    decimal_digits: 2,
    rounding: 0,
    symbol: 'R$',
    symbol_native: 'R$',
  },
  CAD: {
    code: 'CAD',
    decimal_digits: 2,
    rounding: 0,
    symbol: 'CA$',
    symbol_native: '$',
  },
  CHF: {
    code: 'CHF',
    decimal_digits: 2,
    rounding: 0.05,
    symbol: 'CHF',
    symbol_native: 'CHF',
  },
  CNY: {
    code: 'CNY',
    decimal_digits: 2,
    rounding: 0,
    symbol: 'CN¥',
    symbol_native: 'CN¥',
  },
  CZK: {
    code: 'CZK',
    decimal_digits: 2,
    rounding: 0,
    symbol: 'Kč',
    symbol_native: 'Kč',
  },
  DKK: {
    code: 'DKK',
    decimal_digits: 2,
    rounding: 0,
    symbol: 'Dkr',
    symbol_native: 'kr',
  },
  EUR: {
    code: 'EUR',
    decimal_digits: 2,
    rounding: 0,
    symbol: '€',
    symbol_native: '€',
  },
  GBP: {
    code: 'GBP',
    decimal_digits: 2,
    rounding: 0,
    symbol: '£',
    symbol_native: '£',
  },
  HKD: {
    code: 'HKD',
    decimal_digits: 2,
    rounding: 0,
    symbol: 'HK$',
    symbol_native: '$',
  },
  HRK: {
    code: 'HRK',
    decimal_digits: 2,
    rounding: 0,
    symbol: 'kn',
    symbol_native: 'kn',
  },
  HUF: {
    code: 'HUF',
    decimal_digits: 2,
    rounding: 0,
    symbol: 'Ft',
    symbol_native: 'Ft',
  },
  IDR: {
    code: 'IDR',
    decimal_digits: 2,
    rounding: 0,
    symbol: 'Rp',
    symbol_native: 'Rp',
  },
  ILS: {
    code: 'ILS',
    decimal_digits: 2,
    rounding: 0,
    symbol: '₪',
    symbol_native: '₪',
  },
  INR: {
    code: 'INR',
    decimal_digits: 2,
    rounding: 0,
    symbol: 'Rs',
    symbol_native: '₹',
  },
  ISK: {
    code: 'ISK',
    decimal_digits: 2,
    rounding: 0,
    symbol: 'Ikr',
    symbol_native: 'kr',
  },
  JPY: {
    code: 'JPY',
    decimal_digits: 2,
    rounding: 0,
    symbol: '¥',
    symbol_native: '￥',
  },
  KRW: {
    code: 'KRW',
    decimal_digits: 2,
    rounding: 0,
    symbol: '₩',
    symbol_native: '₩',
  },
  MXN: {
    code: 'MXN',
    decimal_digits: 2,
    rounding: 0,
    symbol: 'MX$',
    symbol_native: '$',
  },
  MYR: {
    code: 'MYR',
    decimal_digits: 2,
    rounding: 0,
    symbol: 'RM',
    symbol_native: 'RM',
  },
  NOK: {
    code: 'NOK',
    decimal_digits: 2,
    rounding: 0,
    symbol: 'Nkr',
    symbol_native: 'kr',
  },
  NZD: {
    code: 'NZD',
    decimal_digits: 2,
    rounding: 0,
    symbol: 'NZ$',
    symbol_native: '$',
  },
  PHP: {
    code: 'PHP',
    decimal_digits: 2,
    rounding: 0,
    symbol: '₱',
    symbol_native: '₱',
  },
  PLN: {
    code: 'PLN',
    decimal_digits: 2,
    rounding: 0,
    symbol: 'zł',
    symbol_native: 'zł',
  },
  RON: {
    code: 'RON',
    decimal_digits: 2,
    rounding: 0,
    symbol: 'RON',
    symbol_native: 'RON',
  },
  RUB: {
    code: 'RUB',
    decimal_digits: 2,
    rounding: 0,
    symbol: 'RUB',
    symbol_native: 'руб.',
  },
  SEK: {
    code: 'SEK',
    decimal_digits: 2,
    rounding: 0,
    symbol: 'Skr',
    symbol_native: 'kr',
  },
  SGD: {
    code: 'SGD',
    decimal_digits: 2,
    rounding: 0,
    symbol: 'S$',
    symbol_native: '$',
  },
  THB: {
    code: 'THB',
    decimal_digits: 2,
    rounding: 0,
    symbol: '฿',
    symbol_native: '฿',
  },
  TRY: {
    code: 'TRY',
    decimal_digits: 2,
    rounding: 0,
    symbol: 'TL',
    symbol_native: 'TL',
  },
  USD: {
    code: 'USD',
    decimal_digits: 2,
    rounding: 0,
    symbol: '$',
    symbol_native: '$',
  },
  ZAR: {
    code: 'ZAR',
    decimal_digits: 2,
    rounding: 0,
    symbol: 'R',
    symbol_native: 'R',
  },
};

export default currencies;
