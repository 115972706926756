/**
 * Config object for Amplify
 *
 * Note - USER_PASSWORD_AUTH needed for user migrator
 */
const config = {
  awsAmplify: {
    Auth: {
      region: process.env.NEXT_PUBLIC_APP_REGION,
      userPoolId: process.env.NEXT_PUBLIC_APP_USER_POOL_ID,
      userPoolWebClientId: process.env.NEXT_PUBLIC_APP_APP_CLIENT_ID,
      identityPoolId: process.env.NEXT_PUBLIC_APP_IDENTITY_POOL_ID,
      authenticationFlowType: 'USER_PASSWORD_AUTH',
      correctClockSkew: true, // Allow for incorrect timezones on user devices
      oauth: {
        domain: process.env.NEXT_PUBLIC_APP_USER_POOL_DOMAIN,
        scope: [
          'phone',
          'email',
          'profile',
          'openid',
          'aws.cognito.signin.user.admin',
        ],
        // Defaults given for localhost testing
        redirectSignIn:
          typeof window !== 'undefined' &&
          window.location.hostname === 'localhost'
            ? 'http://localhost:3000/auth/callback'
            : `${process.env.NEXT_PUBLIC_DOMAIN}/auth/callback`,
        redirectSignOut:
          typeof window !== 'undefined' &&
          window.location.hostname === 'localhost'
            ? 'http://localhost:3000/login'
            : `${process.env.NEXT_PUBLIC_DOMAIN}/login`,
        responseType: 'code',
      },
    },
    API: {
      endpoints: [
        {
          name: 'webapi',
          endpoint: process.env.NEXT_PUBLIC_APP_API_URL,
          region: process.env.NEXT_PUBLIC_APP_REGION,
        },
      ],
    },
  },
  // Not setup directly using aws-amplify config (instead used in libs/appsync)
  Appsync: {
    endpoint: process.env.NEXT_PUBLIC_APP_GRAPHQL_ENDPOINT,
    region: process.env.NEXT_PUBLIC_APP_REGION,
  },
  Spreedly: {
    key: '2VliQoIvXLLyW2vTn7kPWotrMId', // Should be the same across staging/live (just TMT credentials different)
  },
  Facebook: {
    pixelId:
      process.env.NEXT_PUBLIC_APP_STAGE === 'prod'
        ? '851558921616060'
        : '394675804771668',
  },
  Google: {
    trackingId:
      process.env.NEXT_PUBLIC_APP_STAGE === 'prod'
        ? 'G-TVR9T0L56Y'
        : 'G-0PST7G69H1',
    placesApiKey:
      process.env.NEXT_PUBLIC_APP_STAGE === 'prod'
        ? 'AIzaSyB08YLI29BL_DZlCF5D_Jo6Fzo8ogMeBuM'
        : 'AIzaSyDne8cjfU5O9dpMBm8cyWd5ZneUHFKUu8I',
    timezoneApiKey: 'AIzaSyB08YLI29BL_DZlCF5D_Jo6Fzo8ogMeBuM',
  },
  skyhook: {
    phone: '(+44) 02080 810 200',
    techEmail: 'techsupport@skyhookadventure.com',
    supportEmail: 'info@skyhookadventure.com',
  },
  links: {
    supportCall:
      'https://outlook.office365.com/owa/calendar/Skyhook1@skyhookadventure.com/bookings/',
  },
  drip: {
    dripID: process.env.NEXT_PUBLIC_ENVIRONMENT === 'prod' ? '7546327' : '',
  },
  firebase:
    process.env.NEXT_PUBLIC_APP_STAGE === 'prod'
      ? {
          apiKey: 'AIzaSyD-KqWb2BAy4je1VibOHXIa23284thHVqU',
          authDomain: 'skyhook-69059.firebaseapp.com',
          projectId: 'skyhook-69059',
          storageBucket: 'skyhook-69059.appspot.com',
          messagingSenderId: '920411006086',
          appId: '1:920411006086:web:553eea5fe07032eab05d07',
          measurementId: 'G-TJVBJJWQTB',
        }
      : {
          apiKey: 'AIzaSyBrfM1VcIqACvhxDHy7f8NTvL7NhW5Up64',
          authDomain: 'skyhook-dev-59015.firebaseapp.com',
          projectId: 'skyhook-dev-59015',
          storageBucket: 'skyhook-dev-59015.appspot.com',
          messagingSenderId: '746522288716',
          appId: '1:746522288716:web:8ad18456832c9e599c8ae9',
        },
  posthog:
    process.env.NEXT_PUBLIC_ENVIRONMENT === 'prod'
      ? 'phc_rCvVbrsNDzS7JmTEhcUq8B2gD60cODEdyVDUp5J5Yq0'
      : 'phc_CrjkOExGDLy4CXCwuht6eEIHDM7VDNsTXAI3tpTATim',
  webPushNotifications: {
    publicKey:
      process.env.NEXT_PUBLIC_APP_STAGE === 'prod'
        ? 'BPehQxKPcXgTP-r7xikr2Ei_JanNm-RkedMXJ-Fu707igwlSXdZBI-esAbQqlJKIEVKgKiygfKEAB3AyV7EA1xM'
        : 'BIzGQq3FkBfPS0R55SSYjkxDn8-Wq0YVCXTm0cIe4TCD8NtAnRRrMoG7QCFOyIIKzT3FL_6Th5ZesrpcmxEhLnc',
  },
};

export default config;
