import { Auth } from '@aws-amplify/auth';
import { authenticatedClient } from '@website/libs/graphql/apollo';
import { BackupPasswordDocument } from '@website/types/graphqlOperations';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { SubmitButton } from '@website/components/forms/fields/SubmitButton';
import { Alert, Button, Form } from 'react-bootstrap';
import { useRouter } from 'next/router';

/**
 * Login submit handler
 */
export async function loginSubmitHandler(
  { email, password }: LoginFields,
  setError: (msg: string) => void,
  onNewPasswordRequired: LoginFormProps['onNewPasswordRequired'],
  onNotVerifiedError: LoginFormProps['onNotVerifiedError'],
  onLoginSuccess: LoginFormProps['onLoginSuccess'],
): Promise<void> {
  try {
    const user = await Auth.signIn(email, password);

    // Handle new password required
    if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
      onNewPasswordRequired(email, password);
    }

    // Otherwise handle success
    else {
      // Backup the user password. Note we need to create a client here directly, as the <AppsyncClient> from _app.ts
      // won't have had it's state updated yet, so it will still be set as unauthenticated.
      const client = authenticatedClient();

      // Don't await the password backup response, so that we speed up the login flow
      client.mutate({
        mutation: BackupPasswordDocument,
        variables: { input: { password } },
      });

      // Submit the success handler
      onLoginSuccess();
    }
  } catch (e) {
    // Handle user not found (can be password error as well with user migrator)
    switch (e.code) {
      case 'UserNotFoundException': // Returned if user migrator fails
        setError('Incorrect username or password - please try again.');
        break;
      case 'NotAuthorizedException':
        setError('Incorrect password - please try again.');
        break;
      case 'UserNotConfirmedException':
        onNotVerifiedError({ email, password });
        break;
      default:
        setError(e.message);
        break;
    }
  }
}

/**
 * Login Form
 */
export default function LoginForm({
  email = '',
  onNewPasswordRequired,
  onLoginSuccess,
  onNotVerifiedError,
  onCancel = () => {},
}: LoginFormProps) {
  const [error, setError] = useState<string>();

  const { push } = useRouter();

  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<LoginFields>({
    defaultValues: {
      email,
      password: '',
    },
  });

  const onForgottenPassword = () => {
    onCancel();
    push(`/login/forgot`);
  };

  return (
    <Form
      onSubmit={handleSubmit((v) =>
        loginSubmitHandler(
          v,
          setError,
          onNewPasswordRequired,
          onNotVerifiedError,
          onLoginSuccess,
        ),
      )}
    >
      {error && <Alert variant="danger">{error}</Alert>}

      <Form.Group controlId="email">
        <Form.Label>Email</Form.Label>
        <Form.Control
          {...register('email')}
          autoComplete="email"
          required
          type="email"
        />
      </Form.Group>

      <Form.Group controlId="password">
        <Form.Label>Password</Form.Label>
        <Form.Control
          {...register('password')}
          autoComplete="current-password"
          required
          type="password"
        />
      </Form.Group>

      <SubmitButton
        block
        isSubmitting={isSubmitting}
        className="mt-4"
        variant="outline-primary"
        text="Login"
      />

      <div className="mt-3">
        <Button
          onClick={onForgottenPassword}
          variant="link"
          data-cy="forgot-password"
        >
          Forgotten Password?
        </Button>
      </div>
    </Form>
  );
}

export interface LoginFields {
  email: string;
  password: string;
}

interface LoginFormProps {
  email?: string;
  onNotVerifiedError: ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => void;
  onNewPasswordRequired: (email: string, password: string) => void;
  onLoginSuccess: () => void;
  onCancel?: () => void;
}
