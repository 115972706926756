import { useContext } from 'react';
import { Store } from '@website/Store';
import dynamic from 'next/dynamic';

const DynamicComponent = dynamic(
  () =>
    import('./ForegroundMessagesSetupDynamicImport').catch(() => {
      // If the dynamic import fails, fail gracefully and simply don't load foreground messages
      return null;
    }),
  { ssr: false },
);

/**
 * Enable Foreground notifications
 */
export default function ForegroundMessagesSetup() {
  const { state } = useContext(Store);

  if (!state.isAuthenticated) return null;

  return <DynamicComponent />;
}
