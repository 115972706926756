import { GeoLocation } from '@website/pages/api/geo';
import { useEffect, useState } from 'react';

/**
 * Get the current user location details
 */
export default function useLocation() {
  const [clientLocation, setLocation] = useState<GeoLocation>({});

  useEffect(() => {
    async function getLocationFromNextAPI() {
      try {
        // Get the user location from the Next JS API (see /pages/api/geo for details)
        const full = `${document.location.protocol}//${document.location.host}`;
        const response = await fetch(`${full}/api/geo`, {
          method: 'GET',
        });
        const body = await response.json();
        setLocation(body);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log('Failed to fetch user location.');
      }
    }
    getLocationFromNextAPI();
  }, [setLocation]);

  return clientLocation;
}
