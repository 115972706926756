import useGeolocation from '@website/hooks/geoLocation/useGeolocation';
import { Store } from '@website/Store';
import { useContext, useEffect } from 'react';

type Props = {
  children: React.ReactElement;
};

/**
 * Setup the default currency
 *
 * Fetches the default currency from the user's location and sets it as the
 * preferred currency (unless this is already set)
 */
export default function SetupDefaultCurrency({ children }: Props) {
  const { dispatch } = useContext(Store);

  const geoLocation = useGeolocation();

  useEffect(() => {
    if (geoLocation?.currency?.code) {
      dispatch({
        type: 'SET_DEFAULT_CURRENCY',
        payload: geoLocation.currency.code,
      });
    }
  }, [geoLocation, dispatch]);

  return <>{children}</>;
}
