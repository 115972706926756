import { HttpLink, ApolloLink, split } from '@apollo/client';
import { AuthOptions } from 'aws-appsync-auth-link';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';
import config from '../../../config';

/**
 * Appsync HTTP link
 */
const appsyncHttpLink = new HttpLink({ uri: config.Appsync.endpoint });

/**
 * Appsync Link
 *
 * Uses split to return a http link if doing a query/mutation, and a subscription handler otherwise.
 */
export default function appsyncLink(auth: AuthOptions): ApolloLink {
  return split(
    (op) => (op.query.definitions[0] as any).operation !== 'subscription',
    appsyncHttpLink,
    createSubscriptionHandshakeLink(
      { auth, url: config.Appsync.endpoint, region: config.Appsync.region },
      appsyncHttpLink,
    ),
  );
}
