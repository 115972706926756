import {
  ApolloLink,
  Observable,
  Observer,
  FetchResult,
  ApolloError,
} from '@apollo/client';

/**
 * Offline error link
 *
 * Throws a "Connection lost" error if the device is offline, and terminates the link chain. This is useful for avoiding
 * network requests where we know they will fail.
 */
const offlineErrorLink = new ApolloLink((operation, forward) => {
  // Get the connection status
  const isOnline = navigator?.onLine !== false; // Handles the edge case of `navigator.onLine` not supported

  // Forward on operation if online (to next link)
  if (isOnline) return forward(operation);

  // Otherwise terminate with an error
  return new Observable<FetchResult>((observer: Observer<FetchResult>) => {
    observer.error(
      new ApolloError({ networkError: new Error('Connection lost') }),
    );
    observer.complete();
  });
});

export default offlineErrorLink;
