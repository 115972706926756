import Router from 'next/router';
import * as NProgress from 'nprogress';
import * as React from 'react';

/**
 * Next progress bar
 *
 * Note the css has been moved up to the main stylesheet.
 */
export default function NextNProgress({
  startPosition = 0.3,
  stopDelayMs = 200,
  showOnShallow = true,
}: {
  /**
   * The start position of the bar.
   * @default 0.3
   */
  startPosition?: number;
  /**
   * The stop delay in milliseconds.
   * @default 200
   */
  stopDelayMs?: number;
  /**
   * Whether to show the bar on shallow routes.
   * @default true
   */
  showOnShallow?: boolean;
}) {
  let timer: NodeJS.Timeout | null = null;

  const routeChangeStart = (
    _: string,
    {
      shallow,
    }: {
      shallow: boolean;
    },
  ) => {
    if (!shallow || showOnShallow) {
      NProgress.set(startPosition);
      NProgress.start();
    }
  };

  const routeChangeEnd = (
    _: string,
    {
      shallow,
    }: {
      shallow: boolean;
    },
  ) => {
    if (!shallow || showOnShallow) {
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        NProgress.done(true);
      }, stopDelayMs);
    }
  };

  React.useEffect(() => {
    NProgress.configure({ showSpinner: false });
    Router.events.on('routeChangeStart', routeChangeStart);
    Router.events.on('routeChangeComplete', routeChangeEnd);
    Router.events.on('routeChangeError', routeChangeEnd);

    return () => {
      Router.events.off('routeChangeStart', routeChangeStart);
      Router.events.off('routeChangeComplete', routeChangeEnd);
      Router.events.off('routeChangeError', routeChangeEnd);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
}
