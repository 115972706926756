/* eslint-disable no-console */
import { Auth } from '@aws-amplify/auth';
import React, { ReactNode, useContext, useEffect } from 'react';
import { Store } from '../../Store';
import { setUserToGlobalStore } from '../auth/AuthenticationModal';

/**
 * Authenticate and dispatch the user form the client side
 */
export async function authenticateUserClientSide(
  dispatch: (props: any) => void,
) {
  try {
    // If logged in get the user (will throw if not logged in)
    await Auth.currentAuthenticatedUser();
    await setUserToGlobalStore(dispatch);
  } catch (e) {
    if (e !== 'The user is not authenticated') {
      console.log(e);
    }
  }

  dispatch({ type: 'SET_AUTHENTICATING', payload: false });
}

/**
 * Authenticate the User
 *
 * If the page is using server-side rendering, the user state will be given as props. However if the page is not using
 * server-side rendering, the user must first be authenticated here.
 *
 * Note this must be a lower-level component than the root App (pages/_app) as it must sit below where the context is
 * declared (in <Store/>).
 */
export default function AuthenticateUser({ children }: UserStateToStoreProps) {
  const { dispatch } = useContext(Store);

  // Authenticate the user & dispatch to the Store (global state)
  useEffect(() => {
    authenticateUserClientSide(dispatch);
  }, [dispatch]);

  // Return any children
  return <>{children}</>;
}

export interface UserStateToStoreProps {
  children: ReactNode;
}
