import Head from 'next/head';
import { ReactNode } from 'react';

export interface LayoutProps {
  children: ReactNode;
}

export default function MainLayout({ children }: LayoutProps) {
  return (
    <>
      <Head>
        <title>Skyhook</title>
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon/favicon-16x16.png"
        />
        <link
          rel="mask-icon"
          href="/favicon/safari-pinned-tab.svg"
          color="#5bbad5"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/favicon/apple-touch-icon.png"
        />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <meta
          name="description"
          content="Book adventure holidays with authentic local guides, around the world. Everest Base Camp, Morocco Highlights, Tour du Mont Blanc and more!"
        />
        {/* do not index on dev */}
        {process.env.NEXT_PUBLIC_ENVIRONMENT !== 'prod' && (
          <meta name="robots" content="noindex" />
        )}
      </Head>
      {children}
    </>
  );
}
