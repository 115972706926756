import { useQuery, WatchQueryFetchPolicy } from '@apollo/client';
import {
  FetchGeoLocationInfoDocument,
  FetchGeoLocationInfoQuery,
} from '@website/types/graphqlOperations';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';

const expirationTime = 1000 * 60 * 60 * 24;

export default function useGeolocation(): FetchGeoLocationInfoQuery['geoLocation'] {
  const [fetchPolicy, setFetchPolicy] =
    useState<WatchQueryFetchPolicy>('cache-first');

  const { data } = useQuery(FetchGeoLocationInfoDocument, {
    fetchPolicy,
  });

  useEffect(() => {
    const lastFetched = localStorage.getItem('lastFetched');

    // if we haven't fetched before, the query is going to fetch the data, so
    // set the current time as the last set ti
    if (!lastFetched) {
      localStorage.setItem('lastFetched', DateTime.now().toMillis().toString());
    }

    // if we have fetched before, and the last fetch was more than 24 hours ago,
    // fetch again
    if (parseInt(lastFetched) + expirationTime < DateTime.now().toMillis()) {
      // loads from the cache, but updates the cache with the new data
      setFetchPolicy('cache-and-network');
      // sets the last fetched time to now
      localStorage.setItem('lastFetched', DateTime.now().toMillis().toString());
    }
  }, []);

  return data?.geoLocation;
}
