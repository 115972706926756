/* eslint-disable react/require-default-props */
import { ForwardedRef, forwardRef } from 'react';
import { Button, Spinner } from 'react-bootstrap';

/**
 * Submit button
 *
 * Shows 'Submitting...' text if the form is submitting.
 *
 * @example
 * // Get submitting state from react hook form
 * const {
 *   register,
 *   // ... other variables needed
 *   formState: { isSubmitting },
 * } = useForm();
 *
 */
export const SubmitButton = forwardRef(function SubmitButton(
  {
    isSubmitting,
    text = 'Submit',
    submittingLabel = 'Submitting...',
    block = false,
    variant = 'primary',
    className = '',
    divClassName = '',
    isDisabled = false,
  }: SubmitButtonProps,
  ref: ForwardedRef<HTMLButtonElement>,
) {
  let buttonContents = <>{text}</>;
  if (isSubmitting) {
    buttonContents = (
      <>
        <Spinner
          animation="border"
          size="sm"
          as="span"
          className="pr-2"
          style={{ position: 'relative', top: -3 }}
        />
        <span className="ps-2">{submittingLabel}</span>
      </>
    );
  }

  return (
    <div className={`${block ? 'd-grid gap-2' : ''} ${divClassName || ''}`}>
      <Button
        variant={variant}
        type="submit"
        disabled={isSubmitting || isDisabled}
        className={className}
        ref={ref}
      >
        {buttonContents}
      </Button>
    </div>
  );
});

interface SubmitButtonProps {
  block?: boolean;

  /**
   * Is submitting
   *
   * This can be taken from `formState`, from react-hook-form
   */
  isSubmitting: boolean;
  /** Submit label */
  text?: string;

  /** label to be used when submitting is in progress */
  submittingLabel?: string;

  variant?: string;

  className?: string;

  divClassName?: string;

  isDisabled?: boolean;
}
