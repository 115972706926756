import { ApolloLink } from '@apollo/client';

/**
 * Clean type name link
 *
 * Strips out the Apollo caching __typename field from mutations, as Appsync can't handle this.
 */
const cleanTypeNameMutationLink = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    // eslint-disable-next-line no-param-reassign
    operation.variables = JSON.parse(
      JSON.stringify(operation.variables),
      (key, value) => (key === '__typename' ? undefined : value),
    );
  }

  return forward(operation).map((data) => data);
});

export default cleanTypeNameMutationLink;
