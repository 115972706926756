import { EventFanProvider, useEventFan } from 'eventfan';
import { useRouter } from 'next/router';
import { ReactNode, useContext, useEffect } from 'react';
import { Store } from '../../Store';

/**
 * Analytics Identify
 *
 * Send user data to EventFan once the user has logged in
 * https://getanalytics.io/api/#analyticsidentify
 *
 */
export function AnalyticsIdentify() {
  const { state } = useContext(Store);
  const { identify } = useEventFan();

  // Send identify event for login to getAnalytics
  useEffect(() => {
    // If the user is logged in
    if (state.user?.id) {
      const isHost = !!state.user.host_roles?.[0];
      const hostProps = isHost ? { tags: ['host'] } : {};

      identify(state.user.id, {
        email: state.user.email,
        firstName: state.user.first_name,
        lastName: state.user.last_name,
        phone: state.user.phone,
        username: state.user.id,
        id: state.user.id,
        ...hostProps,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.user?.id]);

  return null;
}

/**
 * Analytics Page Handling
 */
export function AnalyticsPage() {
  const router = useRouter();
  const { page } = useEventFan();

  // Make an initial page call
  useEffect(() => {
    page();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Setup page tracking
  useEffect(() => {
    const handleRouteChange = (
      _path: string,
      { shallow = false }: { shallow?: boolean },
    ) => {
      // Do not send if shallow, i.e. the page wasn't fully loaded
      if (!shallow) {
        page();
      }
    };
    router?.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router?.events.off('routeChangeComplete', handleRouteChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!!router]);

  return null;
}

/**
 * Analytics Setup
 *
 * Note we don't load the destinations here as that is done by the CookieConsent
 * component instead (once consent is approved). This component will however
 * still buffer up all events so they can be sent once the destinations are loaded.
 */
export default function AnalyticsSetup({ children }: AnalyticsSetupProps) {
  if (typeof window === 'undefined') return <>{children}</>;

  return (
    <EventFanProvider>
      <AnalyticsIdentify />
      <AnalyticsPage />
      {children}
    </EventFanProvider>
  );
}

export interface AnalyticsSetupProps {
  children: ReactNode;
}
