/* eslint-disable no-alert */
import { Auth } from '@aws-amplify/auth';
import { useState } from 'react';
import { Alert, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { SubmitButton } from '../../forms/fields/SubmitButton';

/**
 * Sign up submit handler
 *
 * Attempts to sign the user up using Cognito. Note the user is then added to the legacy database by a Cognito post
 * confirmation trigger, so we don't need to do that on the frontend.
 */
export async function signUpSubmitHandler(
  values: any,
  setUserToBeVerified: SignUpFormProps['setUserToBeVerified'],
  setError: (msg: string) => void,
) {
  try {
    await Auth.signUp({
      username: values.email,
      password: values.password,
      attributes: {
        given_name: values.givenName,
        family_name: values.familyName,
      },
    });

    setUserToBeVerified({ email: values.email, password: values.password });
  } catch (e) {
    // Show the Cognito error message as is
    setError(e.message);
  }
}

/**
 * Sign up form
 *
 * We include both the first & last name fields, as these are set as required on Cognito.
 */
export default function SignUpForm({ setUserToBeVerified }: SignUpFormProps) {
  const [error, setError] = useState<string>();

  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm();

  return (
    <Form
      onSubmit={handleSubmit((values) =>
        signUpSubmitHandler(values, setUserToBeVerified, setError),
      )}
    >
      {error && <Alert variant="danger">{error}</Alert>}

      <Form.Group>
        <Form.Label htmlFor="firstName">First Name</Form.Label> *
        <Form.Control
          autoComplete="given-name"
          id="firstName"
          isInvalid={!!errors.givenName}
          maxLength={50} // Set a sensible limit as this may clash with third-party API integrations otherwise
          required
          {...register('givenName')}
        />
        <Form.Label htmlFor="familyName">Last Name</Form.Label> *
        <Form.Control
          autoComplete="family-name"
          id="familyName"
          maxLength={50} // Set a sensible limit as this may clash with third-party API integrations otherwise
          required
          {...register('familyName')}
        />
        <Form.Label htmlFor="signupemail">Email</Form.Label> *
        <Form.Control
          autoComplete="email"
          id="signupemail"
          // Length max from https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_AdminCreateUser.html
          maxLength={128}
          required
          {...register('email')}
          type="email"
        />
        <Form.Label htmlFor="password">Password</Form.Label> *
        <Form.Control
          autoComplete="new-password"
          id="password"
          // Length min/max from https://docs.aws.amazon.com/cognito/latest/developerguide/limits.html#resource-quotas
          minLength={6}
          maxLength={99}
          required
          {...register('password')}
          type="password"
        />
      </Form.Group>

      <div className="mt-4">
        <SubmitButton
          block
          isSubmitting={isSubmitting}
          variant="outline-primary"
        />
      </div>
    </Form>
  );
}

export interface SignUpFormProps {
  setUserToBeVerified: ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => void;
}
